import Vue from 'vue'
import axios from '@/libs/axios'

const getCacheKey = (endpoint, params) => {
  let hash = endpoint

  if (typeof params === 'object') {
    Object.keys(params).forEach(prop => {
      hash += `__${prop}_${params[prop]}`
    })
  }

  return hash
}

export default {
  namespaced: true,
  state: {
    cache: {
      items: {},
      validFor: 1000 * 60 * 10, // 10 minutes
    },
    userData: {
      id: null,
      languageCode: '',
      participant: {
        firstName: '',
        lastName: '',
        bio: '',
      },
      userGroup: {
        title: '',
      },
    },
    selectedEntity: {},
  },
  getters: {
    cached: (state, getters) => key => {
      if (getters.isValid(key)) {
        return state.cache.items[key].data
      }

      return undefined
    },

    userData: state => state.userData,

    isValid: state => key => {
      const item = state.cache.items[key]
      const timestamp = Date.now()

      return (item && state.cache.validFor > (timestamp - item.timestamp))
    },

    selectedEntity: state => state.selectedEntity,
  },
  mutations: {
    cache: (state, payload) => {
      // Cache item can be used to stop an ajax request without storing the data on this object
      state.cache.items[payload.key] = {
        timestamp: Date.now(),
        data: payload.data ? payload.data : null,
      }
    },

    userData: (state, payload) => {
      Object.entries(payload).forEach(([i, val]) => {
        Vue.set(state.userData, i, val)
      })
    },

    selectedEntity: (state, payload) => {
      state.selectedEntity = payload
    },

    clearCache: (state) => {
      state.cache.items = {}
    },
  },
  actions: {
    jwtLogin(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/jwt/login', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserGroupRights(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/usergrouprights/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserLanguage({ getters, commit }, data) {
      axios.put('/users/language', { ...data })
      commit('userData', {
        ...getters.userData,
        ...data,
      })

      localStorage.setItem('userData', JSON.stringify(getters.userData))
    },
    updateUserData({ getters, commit }, data) {
      return axios.put('/users/profile', { ...data })
        .then(res => {
          commit('userData', {
            ...getters.userData,
            ...data,
          })

          localStorage.setItem('userData', JSON.stringify(getters.userData))

          return res
        })
    },
    fetchGenericList({ getters, commit }, payload) {
      const queryParams = payload.params
      const endpoint = payload.url
      const key = getCacheKey(endpoint, queryParams)

      if (getters.isValid(key)) {
        return getters.cached(key)
      }

      return new Promise((resolve, reject) => {
        axios
          .get(endpoint, { params: queryParams })
          .then(response => {
            commit('cache', {
              key,
              data: response,
            })

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUsers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'users/list' })
    },
    fetchUserGroups({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'user-groups/list' })
    },
    fetchStations({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'stations/list' })
    },
    fetchDockings({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'dockings/list' })
    },
    fetchSensors({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'sensors/list' })
    },
    fetchVessels({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'vessels/list' })
    },
    fetchCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'categories/list' })
    },
    fetchCompanies({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'companies/list' })
    },
    fetchCountries({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'countries/list' })
    },
    fetchPostCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'post-categories/list' })
    },
    fetchRoles({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'roles' })
    },
    fetchLanguages({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'languages/list' })
    },
    fetchParticipants({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'participants/list' })
    },
  },
}
