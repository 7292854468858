import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  getUserRights,
  isUserLoggedIn,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      alias: '/dockings/list',
      name: 'dockings-list',
      component: () =>
        import(
          '@/views/dockings/DockingList.vue'
        ),
      meta: {
        authority: 'Measurements_Docking_View',
      },
    },
    {
      path: '/docking/:id',
      name: 'dockings-view',
      component: () => import('@/views/dockings/DockingView.vue'),
      meta: {
        authority: 'Measurements_Docking_View',
      },
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/views/account-settings/AccountSettings.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/change-log',
      name: 'changelog',
      component: () => import('@/views/changelog/Changelog.vue'),
      meta: {
        pageTitle: 'App Version',
        breadcrumb: [
          {
            text: 'Changelog',
            active: true,
          },
        ],
      },
    },
    {
      path: '/announcements/:categoryID?',
      name: 'announcements',
      component: () =>
        import(
          '@/views/announcements/announcements-list/AnnouncementsList.vue'
        ),
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () =>
        import(
          '@/views/notifications/notifications-list/NotificationsList.vue'
        ),
      meta: {
        pageTitle: 'Notifications',
        authority: 'Measurements_Docking_View',
        breadcrumb: [
          {
            text: 'Notifications',
            active: true,
          },
        ],
      },
    },
    {
      path: '/announcement/:id',
      name: 'announcement-single',
      component: () =>
        import(
          '@/views/announcements/announcements-single/AnnouncementsSingle.vue'
        ),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Blog',
            to: '/announcements',
          },
        ],
      },
    },
    {
      path: '/measurements/list',
      name: 'measurements-list',
      component: () =>
        import(
          '@/views/measurements/MeasurementList.vue'
        ),
      meta: {
        authority: 'Measurements_Measurement_View',
      },
    },
    {
      path: '/sensors/list',
      name: 'sensors-list',
      component: () =>
        import(
          '@/views/sensors/SensorList.vue'
        ),
      meta: {
        authority: 'Station_Management_Sensor_View',
      },
    },
    {
      path: '/vessels/list',
      name: 'vessels-list',
      component: () =>
        import(
          '@/views/vessels/VesselList.vue'
        ),
      meta: {
        authority: 'General_Vessel_View',
      },
    },
    {
      path: '/companies',
      name: 'companies-list',
      component: () =>
        import('@/views/companies/companies-list/CompanyList.vue'),
      meta: {
        authority: 'Users_Company_View',
      },
    },
    {
      path: '/companies/edit/:id?',
      name: 'companies-edit',
      component: () =>
        import('@/views/companies/companies-edit/CompanyEdit.vue'),
      meta: {
        authority: 'Users_Company_Edit',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
    },
    {
      path: '/announcements-admin',
      name: 'announcements-list',
      component: () =>
        import(
          '@/views/announcements-admin/announcements-list/AnnouncementsList.vue'
        ),
      meta: {
        authority: 'General_Announcement_View',
      },
    },
    {
      path: '/announcements/edit/:id?',
      name: 'announcements-edit',
      component: () =>
        import(
          '@/views/announcements-admin/announcements-edit/AnnouncementsEdit.vue'
        ),
      meta: {
        authority: 'General_Announcement_Edit',
      },
    },
    {
      path: '/post-categories',
      name: 'post-categories-list',
      component: () =>
        import(
          '@/views/post-categories/post-categories-list/PostCategoriesList.vue'
        ),
      meta: {
        authority: 'General_Post_Category_View',
      },
    },
    {
      path: '/users',
      name: 'users-list',
      component: () => import('@/views/users/users-list/UsersList.vue'),
      meta: {
        authority: 'Users_User_View',
      },
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/users/users-view/UsersView.vue'),
      meta: {
        authority: 'Users_User_View',
      },
    },
    {
      path: '/users/edit/:id?',
      name: 'users-edit',
      component: () => import('@/views/users/users-edit/UsersEdit.vue'),
      meta: {
        authority: 'Users_User_Edit',
      },
    },
    {
      path: '/user-groups',
      name: 'user-groups-list',
      component: () =>
        import('@/views/user-groups/user-groups-list/UserGroupsList.vue'),
      meta: {
        authority: 'Users_User_Group_View',
      },
    },
    {
      path: '/user-groups/edit/:id?',
      name: 'user-groups-edit',
      component: () =>
        import('@/views/user-groups/user-groups-edit/UserGroupsEdit.vue'),
      meta: {
        authority: 'Users_User_Group_Edit',
      },
    },
    {
      path: '/station-parameters',
      name: 'station-parameters-list',
      component: () => import('@/views/station-parameters/StationParameterList.vue'),
      meta: {
        authority: 'General_StationParameter_View',
      },
    },
    {
      path: '/main-parameters',
      name: 'main-parameters-list',
      component: () => import('@/views/main-parameters/MainParameterList.vue'),
      meta: {
        authority: 'General_MainParameter_View',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password/:key',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/authentication/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const tauthority = to.matched[0].meta.authority

  if (!isLoggedIn && to.meta.resource !== 'Auth') {
    // Redirect to login if not logged in
    return next({ name: 'login' })
  }

  if (tauthority && !getUserRights().includes(tauthority)) {
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
