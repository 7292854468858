import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserId = () => JSON.parse(localStorage.getItem('userData')).id

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getUserRights = () => {
  const user = getUserData()
  const rights = []
  if (user && user.userGroup) {
    user.userGroup.userGroupRights.forEach(right => {
      rights.push(right.authority)
    })
  }
  return rights
}

export const isRoleType = roleType => {
  const user = getUserData()
  return user && user.userGroup && user.userGroup.title === roleType
}

export const isAdmin = () => true

export const isUser = () => isRoleType('USERS')

export const getHomeRouteForLoggedInUser = () => process.env.VUE_APP_PATH_URL
